.App {
  text-align: center;
}
  .App.expanded {}
    .App.expanded .App-map {
      height: 20%;
    }
    .App.expanded .App-info {
      height: 75%;
    }
/*  .App.fullscreen {}
    .App.fullscreen .App-map {
      bottom: 0;
      height: auto;
    }
    .App.fullscreen .App-info {
      height: 0;
    }
*/
.App-header {
  align-items: center;
  background-color: #2e355e;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  height: 5vh;
  justify-content: center;
}
  .App-header a {
    text-decoration: none;
  }
  .App-header .app-name {
    color: white;
    line-height: 2vh;
  }
  .App-header .center-on-me, .App-header .settings {
    height: 3vh;
    position: absolute;
    top: 1vh;
    width: 3vh;
  }
  .App-header .center-on-me {
    filter: grayscale(1);
    left: 1.5vh;
    opacity: 0.5;
    line-height: 3vh;
    transition: all 0.5s;
  }
    .App-header .center-on-me.active {
      filter: none;
      opacity: 1;
    }
  .App-header .settings {
    right: 1.5vh;
  }
  .App-header img {
    height: 100%;
    width: 100%;
  }

.App-link {
  color: #61dafb;
}

.App-map {
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 6px;
  height: 55%;
  left: 0;
  position: absolute;
  right: 0;
  top: 5vh;
  transition: height 0.5s;
  z-index: 100;
}

.Map-container {
  height: 100%;
  width: 100%;
}

.Map-pin {
  background: gray;
  border: solid 1px white;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 6px;
  height: 10vw;
  left: 50%;
  max-height: 64px;
  max-width: 64px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  width: 10vw;
  z-index: 1;
}
  .Map-pin img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    z-index: 1
  }

.App-info {
  background: white;
  bottom: 0;
  flex-direction: column;
  height: 40%;
  left: 0;
  overflow: scroll;
  position: absolute;
  right: 0;
  transition: height 0.5s;
}

.App-info-container {
  padding: 0 1rem 1rem;
}
  .App-info-container article {
    text-align: left;
  }
  .App-info-container details {
    margin: 1rem 0;
  }
  .App-info-container img {
    max-width: 100%;
  }

.App-info-how-to {
  display: block;
  height: 60vh;
  margin: auto;
  width: auto;
}

.modal {
  background: rgba(0,0,0,0.5);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}
  .modal .content {
    background: white;
    border-radius: 1rem;
    bottom: 3vh;
    box-shadow: rgba(0, 0, 0, 0.5) 0 2px 6px;
    left: 3vh;
    padding: 1rem;
    position: absolute;
    right: 3vh;
    text-align: left;
    top: 3vh;
  }
  .modal .close-x {
    position: absolute;
    right: 1vh;
    top: 1vh;
  }
    .modal .close-x img {
      height: 32px;
      width: 32px;
    }

.slider-horizontal {
  height: 1rem;
  margin: 1.5rem 0;
}

.slider-track {
  height: 1rem;
}

/* selected */
.slider-track-0 {
  background: gray;
  border-radius: 0.5rem;
  height: 1rem;
}

/* remaining */
.slider-track-1 {
  background: silver;
  border-radius: 0.5rem;
  box-shadow: inset 0 6px 6px -6px rgba(0, 0, 0, 0.5);
  height: 1rem;
}

.slider-thumb {
  background: white;
  border: solid 1px black;
  border-radius: 0.5rem;
  bottom: -0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  height: 2rem;
  line-height: 2rem;
  outline: none;
  text-align: center;
  user-select: none;
  width: 2rem;
}

.slider-legend {
  height: 47px;
  margin: 1rem 0;
  position: relative;
}

.slider-car, .slider-bike, .slider-walker {
  background-position: center;
  background-repeat: no-repeat;
  bottom: 0;
  display: block;
  position: absolute;
}

.slider-car {
  background-image: url(/img/slider-car.png);
  height: 38px;
  left: 0;
  width: 48px;
}

.slider-bike {
  background-image: url(/img/slider-bike.png);
  height: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 48px;
}

.slider-walker {
  background-image: url(/img/slider-walker.png);
  height: 47px;
  right: 0;
  width: 24px;
}

.me {
  animation: me 1.25s infinite;
  display: block;
  font-style: normal;
  height: 4vw;
  left: 50%;
  max-height: 24px;
  max-width: 24px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  width: 4vw;
  z-index: 10;
}

.map-center {
  height: 24px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s;
  user-select: none;
  width: 24px;
  z-index: 1;
}
  .map-center.active {
    opacity: 1;
  }

@keyframes me {
  0%   { opacity: 1.00; }
  50%  { opacity: 0.25; }
  100% { opacity: 1.00; }
}

@media screen and (orientation: landscape) {
  .App-header {
    font-size: 16px;
  }
  .App-info {
    left: 20%;
    right: 20%;
  }
  i {
    max-height: 16px;
    max-width: 16px;
  }
  .Map-pin {
    max-height: 32px;
    max-width: 32px;
  }
}
